import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import SEO from "../components/SEO/index"
import HeroService from "../components/Sections/HeroService"
import StyledBackgroundService from "../components/BackgroundService"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardContentTitle,
  CardContentSubtitle,
  CardImg,
} from "../components/CompoundComponents/Card"

import "./mystyles.scss"

const PolyclinicPage = ({ location, data, intl }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundService>
        <HeroService location={location} data="header.service.item-1" />
      </StyledBackgroundService>
      <Section>
        <Columns centered>
          <Column is3>
            <Link to="/thoracic_surgery">
              <Card>
                {/* <Link to={data.node.link}> */}
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[8].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[8].node.id}
                  />
                </CardImg>
                {/* </Link> */}
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-5.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/ultrasound">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[4].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[4].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-11.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/obstetrics_and_gynecology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[6].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[6].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-1.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/resuscitation">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[1].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[1].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-12.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/bronchoscopy">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[0].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[0].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-10.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/general_surgery">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[7].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[7].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-7.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/general_surgery">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[5].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[5].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-6.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/cardiology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[3].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[3].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-3.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/laboratory">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[2].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[2].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-8.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/radiography">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[9].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[9].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-9.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/vascular_surgery">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[10].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[10].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-13.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/neurology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[11].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[11].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-2.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/oncology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[12].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[12].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-4.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/urology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[13].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[13].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-14.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/therapy">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[14].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[14].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-15.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
          <Column is3>
            <Link to="/dermatovenerology">
              <Card>
                <CardImg>
                  <Img
                    fluid={
                      data.doctors.edges[15].node.featuredImage.childImageSharp
                        .fluid
                    }
                    alt={data.doctors.edges[15].node.id}
                  />
                </CardImg>
                <CardContent>
                  <CardContentSubtitle>
                    {intl.formatMessage({
                      id: `doctors.item-16.position`,
                    })}
                  </CardContentSubtitle>
                </CardContent>
              </Card>
            </Link>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export const PolyclinicPageQuery = graphql`
  query {
    doctors: allDoctorsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(PolyclinicPage)
